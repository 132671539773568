import React from "react"

import Layout from "../layout/Layout"
import SEO from "../components/SEO/SEO"
import GlobalStyles from "../styles/global-style";

const NotFoundPage = () => (
  <>
    <GlobalStyles />
    
    <Layout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a place that doesn&#39;t exist... the sadness.</p>
    </Layout>
  </>
)

export default NotFoundPage
